import React, { Fragment } from "react";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { SEO } from "../../components/SEO";
import Footer from "../../components/Footer";

const Categories = ({ data: { allSanityCategory, sanityBlogPage } }) => {
  const { nodes } = allSanityCategory;
  return (
    <Fragment>
      <Layout>
        <section className={"bg-black"}>
          <div className={"container mx-auto py-10"}>
            <h1
              className={"text-5xl font-normal font-lora gg-text min-h-[60px]"}
            >
              {sanityBlogPage?.title}
            </h1>
            <p className={"font-lora max-w-lg text-white mt-5"}>
              {sanityBlogPage?.excerpt}
            </p>
          </div>
        </section>

        <div className={"container mx-auto py-10"}>
          <h2 className={"font-bold text-2xl text-center mb-5"}>
            Online Writing and Strategy
          </h2>
          <section className={"flex gap-10 grid md:grid-cols-2 grid-cols-1"}>
            {sanityBlogPage?.sectionA?.map((category, i) => {
              return (
                <div
                  className={
                    "bg-[#f1f1f16e] rounded-[23px] min-h-[200px] col-span-1"
                  }
                  key={i}
                >
                  <h1
                    className={
                      "border-b border-b-black/[0.44] py-6 px-8 font-lora"
                    }
                  >
                    {category.title}
                  </h1>
                  <p className={"font-lora py-6 px-8"}>
                    {category.description}
                  </p>
                  <div className={"flex"}>
                    <Link
                      to={`/blog/${category.slug.current}`}
                      className={
                        "uppercase border rounded-lg px-10 py-3 text-sm hover:bg-black hover:text-white transition-all ml-auto my-6 mx-8"
                      }
                    >
                      explore
                    </Link>
                  </div>
                </div>
              );
            })}
          </section>

          <h2 className={"font-bold text-2xl text-center mb-5 mt-10"}>
            Special Topics and Passion Projects
          </h2>
          <section className={"flex gap-10 grid md:grid-cols-2 grid-cols-1"}>
            {sanityBlogPage?.sectionB?.map((category, i) => {
              return (
                <div
                  className={
                    "bg-[#f1f1f16e] rounded-[23px] min-h-[200px] col-span-1"
                  }
                  key={i}
                >
                  <h1
                    className={
                      "border-b border-b-black/[0.44] py-6 px-8 font-lora"
                    }
                  >
                    {category.title}
                  </h1>
                  <p className={"font-lora py-6 px-8"}>
                    {category.description}
                  </p>
                  <div className={"flex"}>
                    <Link
                      to={`/blog/${category.slug.current}`}
                      className={
                        "uppercase border rounded-lg px-10 py-3 text-sm hover:bg-black hover:text-white transition-all ml-auto my-6 mx-8"
                      }
                    >
                      explore
                    </Link>
                  </div>
                </div>
              );
            })}
          </section>
          <h2 className={"font-bold text-2xl text-center mb-5 mt-10"}>
            The Rest
          </h2>
          <section className={"flex gap-10 grid md:grid-cols-2 grid-cols-1"}>
            {sanityBlogPage?.sectionC?.map((category, i) => {
              return (
                <div
                  className={
                    "bg-[#f1f1f16e] rounded-[23px] min-h-[200px] col-span-1"
                  }
                  key={i}
                >
                  <h1
                    className={
                      "border-b border-b-black/[0.44] py-6 px-8 font-lora"
                    }
                  >
                    {category.title}
                  </h1>
                  <p className={"font-lora py-6 px-8"}>
                    {category.description}
                  </p>
                  <div className={"flex"}>
                    <Link
                      to={`/blog/${category.slug.current}`}
                      className={
                        "uppercase border rounded-lg px-10 py-3 text-sm hover:bg-black hover:text-white transition-all ml-auto my-6 mx-8"
                      }
                    >
                      explore
                    </Link>
                  </div>
                </div>
              );
            })}
          </section>
        </div>
        {/* <section
          className={
            "bg-black mx-10 px-10 py-16 grid grid-cols-6 gap-10 text-white rounded-[37px] flex flex-wrap items-center mb-5"
          }
          data-aos="fade-in"
          data-aos-offset="200"
          data-aos-delay="50"
          data-aos-duration="800"
          data-aos-easing="ease-in"
        >
          <div
            className={"colspan-0 md:col-span-2 text-center hidden md:block"}
          >
            <StaticImage
              src={"../../images/never-miss-a-new-article.svg"}
              alt={"Subscribe"}
            />
          </div>
          <div className={"col-span-6 md:col-span-4 text-center md:text-left"}>
            <h1 className={"font-bold uppercase text-2xl font-lora"}>
              NEVER MISS A NEW ARTICLE
            </h1>
            <p className={"font-lora mt-2 mb-5"}>
              Subscribe to The Slide Grease and get the latest delivered
              directly to your inbox every Sunday.
            </p>
            <button
              className={
                "bg-gradient-to-r hover:bg-gradient-to-l from-[#F4C943] to-[#FFFF95] text-black py-3 px-10 rounded-lg mt-5 uppercase transition-all"
              }
              data-hs-overlay="#active-campaign-signup-modal"
            >
              Subscribe
            </button>
          </div>
        </section> */}
      </Layout>
      <Footer />
    </Fragment>
  );
};

export default Categories;
export const Head = ({ data: { sanityBlogPage } }) => (
  <SEO metadata={sanityBlogPage?.pageMetadata || {}} />
);
export const query = graphql`
  query {
    allSanityCategory {
      nodes {
        description
        slug {
          current
        }
        title
      }
    }
    sanityBlogPage {
      title
      excerpt
      sectionA {
        title
        description
        slug {
          current
        }
      }
      sectionB {
        title
        description
        slug {
          current
        }
      }
      sectionC {
        title
        description
        slug {
          current
        }
      }
      pageMetadata {
        title
        schema
        keywords
        description
        canonical
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;
